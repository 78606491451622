import React from 'react';
// import Oval from '../../Assets/Images/oval-mobile.png';
import Family from '../../Assets/Images/family.svg';
import Oval from '../../Assets/Images/Oval.png';
import './firstSection.scss';
import AutoSuggestDocComponent from '../AutoSuggestDocComponent/AutoSuggestDocComponent';

const FirstSection: React.FC = () => {
  return (
    <>
      <div
        className="d-none d-inline-flex justify-content-end w-100 position-absolute"
        style={{ zIndex: 1024 }}>
        <img
          className=" d-md-flex img-fluid family-oval-purple"
          src={Oval}
          alt=""
        />
      </div>
      <div
        className="d-inline-flex main-image-container flex-column position-relative
        justify-content-center align-items-start w-100 container-oval">
        <svg
          className="mb-2"
          width="109"
          height="5"
          viewBox="0 0 109 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect width="109" height="5" rx="2.5" fill="white" />
        </svg>
        <h1 className="main-title">
          Lo legal hecho <br className="d-block d-md-none" /> Simple
        </h1>
        <span className="d-inline-block mb-4 main-subtitle">
          Personaliza, firma y comparte tus <br /> documentos en minutos.
        </span>

        <div className="container-search">
          <div className="input-group mb-3 ">
            <AutoSuggestDocComponent className="input-search"/>
            {/* <div className="input-group-append">
              <span className="input-group-text bg-white custom-border">
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div> */}
          </div>
          <div className="d-flex justify-content-center">
            <a href="http://app-dev.simplelegalapp.co/#/" className="main-link text-white">
              <u>Ver listado de documentos</u>
            </a>
          </div>
        </div>
      </div>

      <div className="d-none d-inline-flex position-absolute family-oval-position">
        <img
          className="d-none d-md-flex img-fluid family-oval"
          src={Family}
          alt=""
        />
      </div>
    </>
  );
};

export default FirstSection;
