import {Category} from "../Models";

function toggleClass(element: any, className: string) {
  if (element.classList) {
    element.classList.toggle(className);
  } else {
    // For IE9
    const classes = element.className.split(' ');
    const i = classes.indexOf(className);

    if (i >= 0)
      classes.splice(i, 1);
    else
      classes.push(className);
    element.className = classes.join(' ');
  }
}

export function toggleClassWithId(idName: string, classNameToggle: string) {
  const element = document.getElementById(idName);
  toggleClass(element, classNameToggle);
}

export function toggleClassWithClass(className: string, classNameToggle: string) {
  const element = document.getElementsByTagName(className)[0];
  toggleClass(element, classNameToggle);
}

export function addImageToCategory(categories: Category[], type: "png" | "jpg") {
  return categories.map(category => {
    if (category.icon) {
      const url = categoryImgUrl + category._KEY + "." + type;
      category.icon = url;
    }
    return category;
  })
}

export function goToDocument(key: string) {
  window.location.href = `http://app-dev.simplelegalapp.co/#/document/section/${key}`;
}

export function goToInfoDocument(key: string) {
  window.location.href = `http://app-dev.simplelegalapp.co/#/document/${key}`;
}

export const categoryImgUrl =
  'https://sbxcloud.com/www/simplelegaldev/images/category/category_';
