import React from 'react';
import NavComponent from './NavComponent';
import FirstSection from './FirstSection';
import StepsSection from './StepSection/StepsSection';
import DocumentSection from './DocumentSection/DocumentSection';
import FooterSection from './FooterSection/FooterSection';


const LandingComponent = () => {
  return (
    <div data-testid="searchInput">
      <NavComponent/>
      <FirstSection/>
      <StepsSection/>
      <DocumentSection/>
      <FooterSection/>
    </div>
  );
};

export default LandingComponent;
