import React, {useState} from 'react';
import logo from '../../Assets/Images/logo.png';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,} from 'reactstrap';
import './navComponent.scss';
import {toggleClassWithClass} from '../../Util/Utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import './firstSection.scss';
import AutoSuggestDocComponent from '../AutoSuggestDocComponent/AutoSuggestDocComponent';

const path = "http://app-dev.simplelegalapp.co/#/"

const NavComponent = () => {
  const listNavbarMenuUp: Array<{ label: string, path: string }> = [
    {
      label: 'Guías',
      path: path + "guias"
    },
    {
      label: 'Documentos',
      path: path + "documents"
    },
    {
      label: 'Ayuda',
      path: path + "ayuda"
    }
  ];
  const listNavbarMenuDown: Array<{ label: string, path: string }> = [
    {
      label: "Iniciar sesión",
      path: path + "login"
    },
    {
      label: "Registrarme",
      path: path + "sign_up"
    }
  ];

  const [isOpen, setIsOpen] = useState(false);

  const redirectTo = (route: string) => {
    window.location.href = route;
  };

  const toggle: () => void = () => {
    toggleClassWithClass('body', 'modal-open');
    setIsOpen(prev => !prev);
  };
  return (
    <Navbar color="white" light expand="md" style={{zIndex: 2000}}>
      <NavbarBrand href="http://simplelegalapp.co">
        <img src={logo}
             className="d-none d-md-inline"
             style={{height: '50px'}}
             alt=""/>
        <img src={logo}
             className="d-inline d-md-none"
             style={{height: '40px'}}
             alt=""/>
      </NavbarBrand>
      <div className="d-flex align-items-center">
        {/*<span className="d-flex d-md-none"><FontAwesomeIcon icon={faSearch}/></span>*/}
        <NavbarToggler className="border-0" onClick={toggle}/>
      </div>

      <div className={`${isOpen ? 'navbar-menu text-right p-4' : 'd-none'}`}>
        <span className="navbar-close-button" onClick={toggle}>
          X
        </span>
        <ul className="list-group">
          {listNavbarMenuUp.map((item, index) => (
            <li
              key={index}
              onClick={() => redirectTo(item.path)}
              className=" list-unstyled navbar-menu-text navbar-menu-text-up mb-3">
              {item.label}
            </li>
          ))}
          <li className="list-unstyled">
            <svg
              className="mb-3"
              width="109"
              height="5"
              viewBox="0 0 109 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect width="100" height="5" rx="2.5" fill="#5C6C7F"/>
            </svg>
          </li>

          {listNavbarMenuDown.map((item, index) => (
            <li
              key={index}
              onClick={() => redirectTo(item.path)}
              className="list-unstyled navbar-menu-text navbar-menu-text-down mb-3">
              {item.label}
            </li>
          ))}
        </ul>

        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control bg-white border-right-0 input-search"
            placeholder="Ej: Contrato de arrendamiento"
          />
          <div className="input-group-append">
            <span className="input-group-text bg-white custom-border">
              <FontAwesomeIcon icon={faSearch}/>
            </span>
          </div>
        </div>
      </div>

      <Collapse navbar>
        <Nav className="ml-auto align-items-center" navbar>
          <NavItem className="mr-4">
            <NavLink href="http://app-dev.simplelegalapp.co/#/documents">
              Documentos
            </NavLink>
          </NavItem>
          {/*<NavItem className="mr-4">*/}
          {/*  <NavLink href="">*/}
          {/*    Guías*/}
          {/*  </NavLink>*/}
          {/*</NavItem>*/}
          <NavItem className="mr-4 my-auto">
            <div className="input-group">
              <AutoSuggestDocComponent className="input-search-nav"/>
              {/* <input
                type="text"
                className="form-control bg-white border-right-0 input-search-nav"
                placeholder="Ej: Contrato de arrendamiento"
              />
              <div className="input-group-append">
                <span className="input-group-text bg-white custom-border">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div> */}
            </div>
          </NavItem>
          <NavItem className="mr-4">
            <button
              onClick={() =>
                redirectTo('http://app-dev.simplelegalapp.co/#/login')
              }
              className="btn btn-primary mr">
              Iniciar sesión
            </button>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavComponent;
