import {sbxCoreService} from "./SbxService";
import env from "../Config/Environment";
import {Category, CategoryDocsParams, ResponseData} from "../Models";
import {addImageToCategory} from "../Util/Utils";

export async function getCategoriesWithDocsService(params: CategoryDocsParams): Promise<ResponseData<Category>> {
  const res: ResponseData<Category> = await sbxCoreService.run(env.cloudScripts.categoryDocs, params);
  const docs = sbxCoreService.with("document").find();
  console.log(docs)
  if (res.success) {
    res.items = addImageToCategory(res.items, "png");
  }
  return res;
}


