import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import infoLogo from '../../../Assets/Images/Icons/info_icon.svg';
import './documentSection.scss';
import {goToDocument, goToInfoDocument} from "../../../Util/Utils";
import {Category} from "../../../Models";

const DocumentInfoCard: React.FC<Category> = ({name, icon, documents, _KEY: key}) => {
  return (
    <>
      <div style={{background: '#383EB3', borderRadius: '21px 21px 0px 0px'}}>
        <div className="d-flex p-2 ml-2 pt-3">
          <div>
            <img width="50" src={icon} alt="" className="mr-3"/>
          </div>
          <div>
            <span
              onClick={() => window.location.href = `http://app-dev.simplelegalapp.co/#/documents?category=${key}&show_more=false`}
              className="d-block document-card-title pointer-cursor">{name}</span>
            <a
              href={`http://app-dev.simplelegalapp.co/#/documents?category=${key}&show_more=true`}
              className="d-block document-card-subtitle btn btn-link">
              Ver más <FontAwesomeIcon className="ml-3" icon={faChevronRight}/>
            </a>
          </div>
        </div>
      </div>
      <div>
        <ul className="list-group list-group-flush">
          {documents.map((doc, index) => (
            <li
              className="list-group-item d-flex justify-content-between px-3 align-items-center"
              style={{height: '65px'}}
              key={index}>
              <span onClick={() => goToDocument(doc._KEY)}
                    className="document-card-items-text pointer-event">{doc.name}</span>
              <img onClick={() => goToInfoDocument(doc._KEY)} width="20" src={infoLogo} alt=""/>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default DocumentInfoCard;
