import React from 'react';
import "./stepSection.scss";

type Props = {
  img: string;
  title: string;
  text: string;
  rightPosition: boolean;
};
const StepLanding: React.FC<Props> = ({img, title, text, rightPosition}) => {
  return (

    <div className="row m-0 justify-content-md-between pr-md-5">
      {!rightPosition && <div className="col-12 col-md-6">
        <img src={img} alt="" className="mb-5 step-function-image img-fluid"/>
      </div>}

      {rightPosition && <div className="d-flex d-md-none col-12 col-md-6 text-right">
        <img src={img} alt="" className="mb-5 step-function-image img-fluid"/>
      </div>}

      <div className={`col-12 col-md-6 my-auto d-flex flex-column ${rightPosition ? 'text-left' : 'text-md-right'}`}>
        <span className="mb-3 paragraph-title-step">{title}</span>
        <span className="mb-4 paragraph-step">{text}</span>
      </div>

      {rightPosition && <div className="d-none d-md-block col-12 col-md-6 text-right">
        <img src={img} alt="" className="mb-5 step-function-image img-fluid"/>
      </div>}
    </div>
  );
};

export default StepLanding;
