import React, {useState} from 'react';
import Autosuggest from 'react-autosuggest';
import {getDocuments} from '../../Services/DocumentService';
import {goToDocument} from "../../Util/Utils";

const AutoSuggestDocComponent: React.FC<{ className: string }> = ({className}) => {
  const [search, setSearch] = useState('');
  const [suggestionsDoc, setSuggestionsDoc] = useState([]);
  const loadSuggestions = async (value: string) => {
    const res = await getDocuments(value);
    setSuggestionsDoc(res.items);
  };

  const onChange = (event: any, {newValue}: any) => {
    setSearch(newValue);
  };

  const onSuggestionsFetchRequested = ({value}: any) => {
    loadSuggestions(value);
  };
  const getSuggestionValue = (suggestion: any) => {
    return suggestion.name;
  };
  const onSuggestionSelected = (
    event: any,
    {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}: any,
  ) => {
    goToDocument(suggestion.name);
  };
  const onSuggestionsClearRequested = () => {
    setSuggestionsDoc([]);
  };
  return (
    <Autosuggest
      suggestions={suggestionsDoc}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      onSuggestionSelected={onSuggestionSelected}
      renderSuggestion={(suggestion: any) => <span>{suggestion.name}</span>}
      inputProps={{
        placeholder: 'Ej: Contrato de arrendamiento',
        value: search,
        onChange: onChange,
        className: `form-control bg-white border-right-0 ${className}`,
      }}
    />
  );
};

export default AutoSuggestDocComponent;
