import { SbxCoreService, SbxSessionService } from 'sbx-axios';
import environment from '../Config/Environment';
import Axios from "axios";
const sbxCoreService = new SbxCoreService();
const sbxSessionService = new SbxSessionService(sbxCoreService);
sbxSessionService.initialize(
  parseInt(environment.domain, 10),
  environment.appKey,
);

const  http = Axios.create({
  baseURL: `${environment.baseUrl}/api`,
  headers: {
    'Content-Type': 'application/json',
    'accept': 'application/json, text/plain, */*',
  }
})

sbxCoreService.httpClient.interceptors.response.use(
  function(response: any) {
    // Do something with response data'
    if (
      !response.success &&
      response.error === 'Invalid Token or App-Key provided.'
    ) {
      localStorage.removeItem('token');
      window.location.reload();
    }
    return response;
  }
);
export { sbxSessionService, sbxCoreService, http};
