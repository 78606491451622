import {http, sbxCoreService} from './SbxService';
import environment from "../Config/Environment";

export const getDocuments = async (docSearch: string) => {
  const res = await sbxSearchKeys(docSearch, "document");
  return res.results.length ? sbxCoreService.run('89b98981-906e-4b43-8b1a-8c9a37aca7f7', {
    keys: res.results,
  }) : ({success: true, items: []});
};



export function sbxSearchKeys(search: string, model: "document" | string): Promise<{ success: boolean, results: Array<string> }> {
  return http.get(`/search/v1.5/${model}@${environment.domain}/${search || ""}`)
    .then((res: any) => {
      if (res && res.data) {
        return res.data;
      } else {
        return {success: true, results: []}
      }
    });
}
