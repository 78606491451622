declare var process: {
  env: {
    [key: string]: string;
  };
};

const keys: any = {
  docsByCategories: {
    250: "a719260c-edb5-4e3f-940d-7c6eae68f735"
  }
}
export default {
  domain: process.env.REACT_APP_SBX_DOMAIN,
  appKey: process.env.REACT_APP_SBX_KEY,
  baseUrl: process.env.REACT_APP_SBX_BASE_URL,
  cloudScripts: {
    categoryDocs: keys.docsByCategories[process.env.REACT_APP_SBX_DOMAIN]
  }
};
