import React from 'react';
import StepLanding from './StepLanding';
import FreeTrial from '../../../Assets/Images/works_easy.svg';
import Result from '../../../Assets/Images/result.svg';
import AnswerQuestion from '../../../Assets/Images/answers_questions.svg';
import './stepSection.scss';


const StepsSection = () => {
  const items = [
    {
      title: 'Selecciona uno de nuestros formatos legales',
      text: 'Encuentra 50+ documentos legales disponibles para ti en las áreas más comunes del derecho',
      img: FreeTrial,

    },
    {
      title: 'Responde unas cuantas preguntas',
      text:
        'Nuestro sistema de automatización documental convertirá tus respuestas en un documento legal a la medida',
      img: AnswerQuestion,
      rightPosition: true
    },
    {
      title: 'Firma electrónicamente, descarga y comparte tu documento',
      text: 'Gestiona la firma del documento, descárgalo en varios formatos y compártelo',
      img: Result,
    },
  ];
  return (
    <div className="container p-4">
      <svg
        width="100"
        height="5"
        viewBox="0 0 100 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="100" height="5" rx="2.5" fill="#7755FE"/>
      </svg>
      <h2 className="mb-5 step-main-title">Funciona así de fácil </h2>
      {items.map((i, index) => (
        <StepLanding key={index} text={i.text} title={i.title} img={i.img}
                     rightPosition={i.rightPosition ? i.rightPosition : false}/>
      ))}
    </div>
  );
};

export default StepsSection;
