import React from 'react';
import Logo from '../../../Assets/Images/logo.png';
import Colombia from '../../../Assets/Images/colombia_flag.svg';
import waves from '../../../Assets/Images/footer_waves.svg';
import wavesWeb from '../../../Assets/Images/footer_waves_web.svg';
import './footerSection.scss';

const FooterSection = () => {
  return (
    <>
      <img src={waves} className="d-flex d-md-none w-100 position-absolute waves-footer" alt=""/>
      <img src={wavesWeb} className="d-none d-md-flex w-100 waves-footer-web" alt=""/>
      <div className="d-flex flex-column align-items-center mt-5 mt-md-0">
        <img src={Logo} width="150" className="mb-5" alt="..."/>
        <div className="d-flex flex-column text-center font-weight-bold mb-5">
          <a href="http://app-dev.simplelegalapp.co/#/documents" className="mb-3 font-weight-bold font-weight-bold">Documentos legales</a>
          {/*<span className="mb-3 font-weight-bold">Guías legales</span>*/}
          <span className="mb-3 font-weight-bold">FAQ</span>
          <a href="http://app-dev.simplelegalapp.co/#/terms" className="mb-3 font-weight-bold">Términos y Condiciones</a>
          <a href="http://app-dev.simplelegalapp.co/#/privacy" className="mb-3 font-weight-bold">Política de privacidad</a>
        </div>
        {/*<div className="d-flex justify-content-between mb-4 flex-grow-1">*/}
        {/*  <img width="25px" className="mr-3" src={ig} alt="" />*/}
        {/*  <img width="25px" className="mr-3" src={Facebook} alt="" />*/}
        {/*  <img width="25px" className="mr-3" src={Linkedin} alt="" />*/}
        {/*  <img width="25px" className="mr-3" src={Youtube} alt="" />*/}
        {/*</div>*/}
        <div className="" style={{marginBottom: '12rem'}}>
          <img src={Colombia} alt=""/>
        </div>
      </div>
    </>

  );
};

export default FooterSection;
