import React, {useCallback, useEffect, useState} from 'react';
import DocumentInfoCard from './DocumentInfoCard';
import './documentSection.scss';
import {Category} from "../../../Models";
import {getCategoriesWithDocsService} from "../../../Services/CategoryService";

const size = 3;
const DocumentSection: React.FC = () => {

  const [categoryDocs, setCategoryDocs] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);

  const getCategories = useCallback(
    async () => {
      setLoading(true);
      const res = await getCategoriesWithDocsService({
        keys: [],
        page: 1,
        search: "",
        size
      });
      if (res.success) {
        setCategoryDocs(res.items);
      }
      setLoading(false);
    }, [])

  useEffect(() => {
    getCategories();
  }, [getCategories]);


  return (
    <div className="p-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <svg
              width="100"
              height="5"
              viewBox="0 0 100 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect width="100" height="5" rx="2.5" fill="#7755FE"/>
            </svg>
            <h3 className="mb-5 document-section-main-title">Documentos más populares</h3>
          </div>
        </div>
        <div className="row justify-content-center">
          {loading ? (
             <div className="text-center w-100">
               <div className="spinner-border text-primary m-auto" role="status">
                 <span className="sr-only">Loading...</span>
               </div>
              <p> Cargando documentos...</p>
             </div>
            ) :
            categoryDocs.filter(c => c.documents.length && c.name !== "categoria de pruebas").map((category, index) => (
              <div className="col-12 col-md-6 col-lg-4" key={index}>
                <div className="mb-5">
                  <DocumentInfoCard {...category} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentSection;
